<div class="category-product">
	<div class="category-product-items" [class.filterOpen]="isOpen">
		<div class="product-card category-card card bg-transparent p-3" *ngFor="let data of queryResult.rows">
			<div class="card-img">
				<img src="{{
						data.imageUrl || '../../../../assets/images/collection-product.png'
					}}" alt=" collection-product" />
				<div class="badges d-none">
					<span class="badge bg-aqua-25">New</span>
				</div>
			</div>
			<div class="card-content">
				<a [routerLink]="'/product/' + data.uri" (click)="setSort()"
					class="btn stretched-link p-0 mb-2 title shadow-none" role="button">{{ data.name }}</a>
				<h6 class="brand">{{ data.brand && data.brand.name }}</h6>
				<h6 class="brand">{{ data.code }}</h6>
				<div class="prices d-flex align-items-end gap-25" *ngIf="finalPrice(data)">
					<p class="price">${{ finalPrice(data) }}</p>
				</div>
				<div class="cart-category">
					<a *ngIf="hasCartAccess" (click)="addCartModel(cartModel, data)" role="button" class="btn btn-primary w-100">
						Add to cart
					</a>
				</div>
			</div>
		</div>
	</div>

	<div class="no-results-content" *ngIf="!queryResult.rows.length && isLoad">
		<div class="no-result-img">
			<img src="../../../../assets/images/No-result.png" alt="No-result" />
		</div>
		<h4 class="title">No Results!</h4>
		<p class="description">
			Your search returned no results. Try selecting a different category,
			clearing your search or updating your search terms.
		</p>
	</div>

	<div *ngIf="queryResult.rows.length">
		<nav class="paggination-nav mt-5" aria-label="Page navigation example">
			<ul class="paggination">
				<li class="page-item me-0">
					<button type="button" class="page-link" (click)="onPageChange(currentPageActive - 1)" aria-label="Previous"
						[disabled]="currentPageActive === 1">
						<span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
								fill="none">
								<path d="M11.8002 13.6004L8.2002 10.0004L11.8002 6.40039" stroke="#666D80" stroke-width="1.5"
									stroke-linecap="round" stroke-linejoin="round"></path>
							</svg></span>
					</button>
				</li>
				<li class="page-item" *ngIf="checkCurrentPage()">
					<a class="page-link" (click)="onPageChange(currentPageActive - 1)">
						...
					</a>
				</li>
				<li *ngFor="let page of visiblePages" class="page-item">
					<a class="page-link" href="javascript:void(0)" [ngClass]="{ active: page === currentPageActive }"
						(click)="onPageChange(page)">
						{{ page }}
					</a>
				</li>
				<li class="page-item" *ngIf="checkLastPage()">
					<a class="page-link" (click)="onPageChange(currentPageActive + 1)">
						...
					</a>
				</li>
				<li class="page-item ms-0">
					<button type="button" class="page-link" (click)="onPageChange(currentPageActive + 1)" aria-label="Next"
						[disabled]="currentPageActive === getTotalPages()">
						<span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
								fill="none">
								<path d="M8.2002 13.6004L11.8002 10.0004L8.2002 6.40039" stroke="#666D80" stroke-width="1.5"
									stroke-linecap="round" stroke-linejoin="round"></path>
							</svg></span>
					</button>
				</li>
			</ul>
		</nav>
	</div>
</div>

<ng-template #cartModel let-c="close" let-d="dismiss" class="deleteModal">
	<div class="modal-content" *ngIf="product">
		<div class="modal-header">
			<h1 class="modal-title" id="bulkOrderLabel">Order</h1>
			<button (click)="d('Cross click')" type="button" class="btn btn-secondary-icon" data-bs-dismiss="modal"
				aria-label="Close">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
						<path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
							stroke-linejoin="round" />
					</svg>
				</span>
			</button>
		</div>
		<div class="modal-body">
			<!-- order-content start here... -->
			<div class="order-bulk">
				<div class="order-md-img-content">
					<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
						<div class="order-img" ngxSlickItem *ngFor="let data of product.images">
							<img src="{{data.url}}" alt="order" />
						</div>
					</ngx-slick-carousel>
					<div class="content d-lg-none">
						<h1 class="title">{{ product.name }}</h1>
						<p class="price" *ngIf="finalPrice(product)">
							{{ finalPrice(product) }}
						</p>
					</div>
				</div>
				<div class="order-content">
					<h1 class="title d-none d-lg-block">
						{{ product.name }}
					</h1>
					<p class="price d-none d-lg-block" *ngIf="finalPrice(product)">
						{{ finalPrice(product) }}
					</p>
					<div class="form-group" *ngIf="cartItem.getUniqueColours()">
						<label for="Colour" class="form-label">Colour</label>
						<select #color class="form-select" aria-label="Default select example" id="Colour"
							(change)="cartItem.selectedColour = color.value">
							<option selected>Choose Color</option>
							<option *ngFor="let colour of cartItem.getUniqueColours()" [selected]="cartItem.selectedColour == colour"
								[value]="colour" [selected]="cartItem.selectedColourString == colour">
								{{ colour || "Default" }}
							</option>
						</select>
					</div>
					<app-favorite-select [productId]="product.id" [type]="'model'"></app-favorite-select>
					<div class="form-group" *ngIf="product.decorations && product.decorations.length">
						<label for="Colour" class="form-label">Decoration</label>
						<select #decc class="form-select" aria-label="Default select example" id="Colour"
							*ngIf="selectableDecorations.length" (change)="addDecorationToCart(decc.value, true, false)">
							<option selected>Add New Decoration</option>
							<option *ngFor="let decoration of selectableDecorations" [value]="decoration.id">
								{{ decoration.name }} -
								<span *ngIf="hasCartAccess">${{
									utils.twoDecimalPlacesString(decoration.price)
									}}/ea</span>
							</option>
						</select>
					</div>
					<div class="edit-card card-body decoration-collapse"
						*ngIf="cartItem.selectedDecorations && cartItem.selectedDecorations.length">
						<ul>
							<li *ngFor="
									let decoration of cartItem.selectedDecorations;
									let i = index
								">
								<p>
									<span *ngIf="hasCartAccess">+${{ utils.twoDecimalPlacesString(decoration.price) }}/ea
										-</span>
									{{ decoration.name }}
									<span class="badge bg-red-25 ps-3 py-1" *ngIf="decoration.isRequired">
										- Required
									</span>
									<button *ngIf="!decoration.isRequired"
										class="btn btn-secondary-icon shadow-none p-0 rounded-0 ms-auto" type="button">
										<span (click)="removeDecorationAtIndex(i)">
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5"
													stroke-linecap="round" stroke-linejoin="round"></path>
											</svg>
										</span>
									</button>
								</p>
								<div class="form-group" *ngIf="decoration.options && decoration.options.length">
									<div *ngFor="let option of decoration.options">
										<label id="clientRequired {{ option.value }}" class="sele_color"
											*ngIf="option.name && option.name.length">{{ option.name }}
											<span *ngIf="option.required"> - Required</span></label>
										<input type="text" class="form-control" id="clientRequired {{ option.value }}"
											placeholder="{{ option.name }}" [required]="option.required" [(ngModel)]="option.value" />
									</div>
								</div>
							</li>
						</ul>
					</div>
					<div class="form-group" *ngIf="cartItem.getUniqueSizes()">
						<label for="Colour" class="form-label">Quantity & Size</label>
						<div class="order-size-qty">
							<div class="form-check ps-0" *ngFor="let size of cartItem.getUniqueSizes()">
								<input class="form-check-input d-none" type="radio" name="size" value="" id="sizeQTY1 {{ size }}"
									[checked]="
										cartItem.selectedSize == size.toString().toLowerCase()
									" />
								<label class="form-check-label" for="sizeQTY1 {{ size }}"
									(click)="size && (cartItem.selectedSize = size.toString())">
									{{ size }}
									<div class="qty-input">
										<button (click)="cartItem.incrementQuantity(-1)" class="btn qty-count p-0" data-action="minus"
											type="button">
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													<path d="M5 12H19" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
														stroke-linejoin="round"></path>
												</svg>
											</span>
										</button>
										<input *ngIf="
												size.toString().toLowerCase() ==
												this.cartItem.selectedSize
											" class="product-qty" type="number" name="product-quantity" [(ngModel)]="cartItem.selectedQuantity"
											appNoNegative />
										<input *ngIf="
												size.toString().toLowerCase() !==
												this.cartItem.selectedSize
											" class="product-qty" type="number" name="product-quantity" value="1" readonly />
										<button (click)="cartItem.incrementQuantity(1)" class="btn qty-count p-0" data-action="add"
											type="button">
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													<path d="M12 5V19M5 12H19" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
														stroke-linejoin="round"></path>
												</svg>
											</span>
										</button>
									</div>
								</label>
							</div>
						</div>
					</div>

					<div class="form-group" *ngIf="usePackSizes()">
						<label for="Colour" class="form-label">Packet Type/Size</label>
						<select #packSize class="form-select" (change)="
								selectPackSize(packSize.value); packSize.value = 'Select Pack'
							" id="Colour">
							<option selected>Select Pack</option>
							<option *ngFor="let unit of product.packSizes" [value]="unit.name" [selected]="
									cartItem.selectedPack &&
									cartItem.selectedPack.name == unit.name
								">
								{{ unit.name | titlecase }} :
								{{ unit.itemCount }}
							</option>
						</select>
					</div>

					<div class="form-group" *ngIf="cartItem.selectedPack">
						<input type="text" class="form-control" id="Type/Size"
							value=" Total Quantity : {{ cartItem.totalQuantity }}" readonly />
					</div>

					<div class="download_btn mb-3" *ngIf="product && product.dataSheetUrl">
						<a class="btn btn-secondary w-100" role="button" href="{{ product && product.dataSheetUrl }}"
							target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
								fill="none">
								<path
									d="M16.75 12.25V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V12.25"
									stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M13.75 7L10 3.25L6.25 7" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M10 3.25V12.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
							</svg>
							Download Product Datasheet</a>
					</div>
					<div class="w-100">
						<button [disabled]="!canAddToCart() || isLoading" (click)="addToCart()" *ngIf="hasCartAccess" role="button"
							class="btn btn-primary w-100">
							<span *ngIf="!isLoading">Add to cart</span>
							<div class="spinner-border" role="status" *ngIf="isLoading">
								<span class="visually-hidden">Loading</span>
							</div>
						</button>
					</div>
				</div>
			</div>
			<!-- order-content end here... -->
		</div>
	</div>
</ng-template>

<ng-template #collectionModal let-close="close" let-d="dismiss" class="deleteModal">
	<div class="modal-content">
		<div class="modal-header">
			<h1 class="modal-title" id="allocationOrderLabel">
				Available Allocations
			</h1>
			<button (click)="d('Cross click')" type="button" class="btn btn-secondary-icon" data-bs-dismiss="modal"
				aria-label="Close">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
						<path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
							stroke-linejoin="round" />
					</svg>
				</span>
			</button>
		</div>
		<div class="modal-body">
			<h4 class="title mb-3">
				You have allocation available for this product.
			</h4>
			<div class="available-allocation d-flex align-items-stretch gap-4">
				<button class="btn btn-secondary w-100 align-items-center" type="button" (click)="close()">
					Do Not Use Allocation
				</button>
				<button class="btn btn-secondary w-100" type="button" *ngFor="let item of allocationCollections"
					(click)="close(item.id)">
					{{ item.name }}
					<br />
					Available Items: {{ item.count }}
				</button>
			</div>
		</div>
	</div>
</ng-template>