import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { ProductService } from '../../services/product.service';
import { NewDecoration, NewProduct } from '../../model/ddb.model';
import { NewCartItem, ServerCartItem } from '../../model/cart.model';
import { isNullOrUndefined } from 'util';
import { SizeChartService } from '../../services/sizeChart.service';
import { numberFromString } from '../../util/sortObjects';
import { SecurityService } from '../../services/security.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { assign } from 'lodash';
import { CollectionService } from '../../services/collection.service';
import { QueryResult } from '../../model/query.filter.class';
import { ICollection, IShortAllocationCollection } from '../../model/collection.model';
import { CartService } from '../../services/cart.service';
import { SessionApi } from '../../api/session.api';
import { NotificationsService } from 'angular2-notifications';
import { Subject, debounceTime, forkJoin, takeUntil } from 'rxjs';
import { Utils } from '../../util/utils';
import { CustomerUserService } from '../../services/customerUser.service';
import * as exactMath from "exact-math";
import { Pipe, PipeTransform } from '@angular/core';
import { legacyFeatures } from '../../util/string.util';
import { AuthService } from '../../services/auth.service';
import { GlobalApi } from '../../api/global.api';
import { IShippingDetails, ShippingDetailsClass } from '../../model/shippingDetail.model';
import { SlickCarouselComponent } from 'ngx-slick-carousel';



@Pipe({
  name: 'wrapText'
})

export class WrapTextPipe implements PipeTransform {
  transform(value: string | null): string {
    if (!value) return '';

    const maxLength = 40;
    const regex = new RegExp(`.{1,${maxLength}}`, 'g');
    return value.match(regex)?.join('\n') || '';
  }
}
//dummy class to sync with cartItem
export class BulkOrderContainer {
  Colour?: string;

  //The selected Pack, if null, assume Singular/1
  Pack: any;

  Items: Array<BulkOrderItem>;

  //Selected Decorations and options
  Decorations?: Array<NewDecoration>;

  selectableDecorations: NewDecoration[] = [];
}

export class BulkOrderItem {
  //The Variation Guid
  Guid: string;
  Size: string;
  Units?: number;
}

type rowOrCol = { id: number };

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: []
})
export class ProductDetailComponent implements OnInit {
  public isPreview: boolean = false;
  public product: NewProduct = new NewProduct();
  public cartItem: NewCartItem = new NewCartItem();
  public selectableDecorations: NewDecoration[] = [];
  public hasCartAccess: boolean = false;
  public hasAutomaticCollection: boolean = false;
  public collectionResult: QueryResult<ICollection> = new QueryResult();
  public allocationCollections: (IShortAllocationCollection & { name: string })[] = [];
  @ViewChild('collectionModal')
  public collectionModal: ElementRef;
  public userShippingDetails: IShippingDetails | null;
  /** Bulk Orders */
  public bulkOrderData: Array<BulkOrderContainer> = [];
  public currColor = '';
  public selectedCartItems = new EventEmitter<NewCartItem[]>();
  public existingCartItems: ServerCartItem[] = [];
  public customerId: number;
  public features: string[] = [];
  public description: string[] = [];
  private unsubscribe$ = new Subject<void>();
  public isAdmin: boolean | undefined = false;
  public isRemoving: boolean;
  public deleteItemId: number | null;
  @ViewChild('slickModal') thumbnailCarousel: SlickCarouselComponent;
  // @ViewChild('mainCarousel') mainCarousel: SlickCarouselComponent;
  selectedImageIndex: number = 0;
  selectedDecoration: any;
  isLoading: boolean;
  constructor(
    public activatedRoute: ActivatedRoute,
    private productService: ProductService,
    private sizeChartsService: SizeChartService,
    public securityService: SecurityService,
    private modalService: NgbModal,
    private collectionsService: CollectionService,
    private cartService: CartService,
    private session: SessionApi,
    private notification: NotificationsService,
    public router: Router,
    public utils: Utils,
    private notifications: NotificationsService,
    private customerUserService: CustomerUserService,
    private authService: AuthService,
    private globals: GlobalApi
  ) { }

  scroll() {
    window.scroll(0, 0);
  }

  slideConfig = {
    slidesToShow: 6,
    slidesToScroll: 1,
    asNavFor: ".product-slider-for",
    dots: false,
    arrows: true,
    vertical: true,
    verticalSwiping: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          arrows: true,
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          arrows: false,
          vertical: false,
          verticalSwiping: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          arrows: false,
          vertical: false,
          verticalSwiping: false,
        },
      }
    ]
  };

  secondSlideConfig = {
    slidesToShow: 1,
    arrows: false,
    asNavFor: ".product-slider-nav",
    vertical: true,
    autoplay: false,
    verticalSwiping: true,
    centerMode: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: false,
          swipe: false,
        },
      }
    ]
  };

  ngOnInit() {
    this.scroll();
    let productUri = window.location.href.replace(/^.*\/product?\//, '');
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        if (window.location.href.includes('/product/')) {
          const productUri = window.location.href.replace(/^.*\/product?\//, '');

          this.getProductUri(productUri);
        }
      }
    });
    this.getProductUri(productUri);
    this.monitorCartAccess();

    this.isAdmin = this.session.$userData?.value?.isAdmin;
    if (!this.isAdmin) {
      this.loadLastAddress();
    }
  }

  loadLastAddress() {
    this.userShippingDetails = this.session.getUserProperty('shippingDetails');
    if (!this.userShippingDetails?.addressName && !this.userShippingDetails?.streetAddress && this.checkAuthenticated()) {
      this.cartService.getLastAddress().pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
        this.userShippingDetails = res;
      });
    }
  }

  /**
* Generates easily read display text for any given shipping details item
* @param details
* @param includeName
*/
  addressDisplayText(details: (ShippingDetailsClass & { displayName?: string }), includeName = false) {
    return (includeName && (details.addressName || details.displayName) ? (details.displayName || details.addressName) + ': ' : '') +
      (details.streetAddress ? details.streetAddress + ' ' : '') +
      (details.streetAddress2 ? details.streetAddress2 + ' ' : '') +
      (details.suburb ? details.suburb : '');
  }

  checkAuthenticated() {
    return this.authService.isAuthenticated();
  }

  setStickyMenu(type: string) {
    window.scroll(0,0);
    this.authService.setStickyMenu(type);
  }

  getProductUri(productUri: string) {
    this.activatedRoute.data.subscribe(data => {
      this.isPreview = !!data.preview;
      if (this.isPreview) {
        let product = localStorage.getItem('PreviewProduct');

        if (!product) {
          this.notifications.error('product preview', 'no product found for preview');
        } else {
          const parseProduct = JSON.parse(product);
          parseProduct.price = this.getAdminPrice(parseProduct);
          this.fetchDescriptionandFetures(parseProduct);
          this.withProduct(parseProduct);
        }
      } else if (this.checkAuthenticated()) {
        this.productService.getProductByUri(productUri)
          .pipe(
            debounceTime(1000),
            takeUntil(this.unsubscribe$)
          )
          .subscribe((product) => {
            this.fetchDescriptionandFetures(product);
            this.withProduct(product);
          }, error => {
            this.notification.error('Fetch Product', error.error && error.error.message);
          });
      } else {
        this.productService.getUnAuthProductByUri(productUri)
          .pipe(
            debounceTime(1000),
            takeUntil(this.unsubscribe$)
          )
          .subscribe((product) => {
            this.fetchDescriptionandFetures(product);
            this.withProduct(product);
          }, error => {
            this.notification.error('Fetch Product', error.error && error.error.message);
          });
      }
    });
  }

  fetchDescriptionandFetures(product) {
    if (product.description) {
      try {
        this.description = JSON.parse(product.description);
      } catch (e) {
        this.description = [product.description];
      }
    } else {
      this.description = [];
    }
    if (product.features) {
      this.features = legacyFeatures(product.features);
    } else {
      this.features = [];
    }
  }

  public withProduct(product: NewProduct) {
    this.product = product;

    if (this.product.variations && this.product.variations.length) {
      this.product.variations = this.product.variations.sort((a, b) => {
        if (a.displayOrder === b.displayOrder) {
          if (a.displayOrder != null && b.displayOrder != null) {
            return (a.id || 0) - (b.id || 0);
          } else {
            if (a.displayOrder == null) {
              return 1;
            } else {
              return -1;
            }
          }
        } else {
          if (a.displayOrder != null && b.displayOrder != null) {
            return a.displayOrder - b.displayOrder;
          } else {
            return a.displayOrder == null ? 1 : -1;
          }
        }
      });
    }

    // this.activateImage('pic-default');

    this.cartItem = new NewCartItem(this.product);

    const color = this.cartItem.getUniqueColours();
    if (color && color.length == 1) {
      this.cartItem.selectedColour = color[0];
    }
    this.addRequiredDecorations();

    if (this.product.sizeChartId) {
      this.loadSizeChart();
    }

    this.setSelectableDecorations();
    this.fetchProductAllocation();
  }

  private getAdminPrice(product: NewProduct) {
    return numberFromString(product.basePrice) || 0;
  }

  public monitorCartAccess() {
    this.securityService.isAutomaticCollectionSelection()
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(hasAutomaticCollection => this.hasAutomaticCollection = hasAutomaticCollection);

    this.securityService.hasCartAccess()
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(hasCartAccess => this.hasCartAccess = hasCartAccess);
  }

  /** Load the size chart data from the sizeChartId on the product */
  public loadSizeChart() {
    if (this.product.sizeChartId && this.checkAuthenticated()) {
      this.sizeChartsService.getById(this.product.sizeChartId)
        .pipe(
          takeUntil(this.unsubscribe$)
        )
        .subscribe(chart => {
          this.product.sizeChart = chart;
        })
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
 * @description Iterates through the decorations available on the product, and adds all required
 * decorations to the cart item.
 */
  public addRequiredDecorations() {
    if (!this.product) throw new Error("Cannot add required decorations without a product");
    if (!this.product.decorations || !this.product.decorations.length) return;
    this.product.decorations.forEach(decoration => {
      if (decoration.isRequired) this.addDecorationToCart(decoration.id, false, true);
    });
  }

  /**
  * @description Updates the cart item by cloning the decoration and adding it to the selected decoration list
  * @param {NewDecoration} decoration the decoration to add to the cart
  * @param {boolean} updateSelectable false will cause the method to not trigger the update of the decorations. Used for bulk adding behaviour. Defaults to true.
  * @param {boolean} isRequired sets the required state of the decoration. Should be false for subsequent required decorations after initial setup. So that
  *  if multiple copies a required decoration exist, the subsequent ones can be removed
  */
  public addDecorationToCart(decorationId: string | number | undefined, updateSelectable: boolean = true, isRequired = true) {
    if (decorationId) {
      const decoration = this.product.decorations.find(decc => decc.id == +decorationId);

      const decorationClone: NewDecoration = JSON.parse(JSON.stringify(decoration));

      decorationClone.isRequired = isRequired;

      this.cartItem.selectedDecorations?.push(decorationClone);

      if (updateSelectable) {
        this.setSelectableDecorations();
      }
    }

    this.selectedDecoration = null;
  }

  /**
 * @description Adjusts the list of selectable decorations relative to the maximum number of each decoration that can appear on a product
 * @returns {void}
 */
  public setSelectableDecorations(): void {
    const signature = "ProductDetailComponent.getSelectableDecorations: ";
    if (!this.product.decorations || !this.product.decorations.length) {
      this.selectableDecorations = [];
      return;
    }

    if (!this.cartItem || !this.cartItem.selectedDecorations || !this.cartItem.selectedDecorations.length) {
      this.selectableDecorations = this.product.decorations;
      return;
    }

    this.selectableDecorations = this.product.decorations.filter(decoration => {
      if (isNullOrUndefined(decoration.maxPerProduct)) {
        return true;
      }

      // Ensure the product doesn't have the maxed out decorations
      const instances = this.cartItem.selectedDecorations?.filter(
        selected => selected.id === decoration.id
      ).length;

      if (instances === 0) {
        return true
      }

      const result = instances && instances < decoration.maxPerProduct;
      return result;
    });
  }

  public openSizeModel(content) {
    this.modalService.open(content, {
      size: 'lg', centered: true, backdrop: true, windowClass: 'bulk-order'
    }).result.then(() => {
    }, () => { });
  }

  checkItems() {
    if (this.cartItem.selectedDecorations?.length) {
      return 'flex-start';
    } else {
      return 'center';
    }
  }
  /** Returns the cell data from any given row/cell combination in the size chart */
  public getCellData(row: rowOrCol, col: rowOrCol) {
    const signature = "ProductDetail.getCellData: ";

    if (!this.product || !this.product.sizeChart) return '';


    const cell = this.product.sizeChart.cells.find(cell =>
      cell.columnId === col.id && cell.rowId === row.id
    );

    if (cell) {
      return cell.value;
    }

    return "";
  }

  usePackSizes = (): boolean => !!(this.product.packSizes && this.product.packSizes.length);

  canAddToCart = (): boolean => !!this.cartItem.selectedVariation && (
    !this.usePackSizes() || !!this.cartItem.selectedPack
  );

  addToCart() {

    if (this.cartItem.selectedDecorations && this.cartItem.selectedDecorations.length) {
      let hasDecorationOptionError = false;
      for (let i = 0; i < this.cartItem.selectedDecorations.length; i++) {
        const decoration = this.cartItem.selectedDecorations[i];
        if (!decoration.options || !decoration.options.length) { continue; }

        const requiredMissingData = decoration.options.filter(option => option.required && (!option.value || !option.value.length));
        if (requiredMissingData && requiredMissingData.length) {
          hasDecorationOptionError = true;
          break;
        }
      }

      if (hasDecorationOptionError) {
        this.notification.warn('Processing', '"Ensure required options for all selected decorations have been correctly filled.');
        return;
      }
    }

    if (this.collectionResult && this.collectionResult.count && this.collectionResult.rows.some(item => !!item.allocationAvailable)) {
      if (this.allocationCollections.length === 0) {
        this.performAddToCart();
      } else {
        // Use the first matching collection
        const suitableCollections = this.allocationCollections.filter(collection => collection.count >= Number(this.cartItem.selectedQuantity));

        if (suitableCollections.length === 1) {
          this.cartItem.allocationCollectionId = suitableCollections[0].id;
          this.performAddToCart();
        } else if (suitableCollections.length > 1 && this.hasAutomaticCollection) {
          this.cartItem.allocationCollectionId = suitableCollections[0].id;
          this.performAddToCart();
        } else {
          this.openCollectionModal();
        }
      }
    } else {
      this.performAddToCart();
    }
  }

  public openCollectionModal() {
    this.modalService.open(this.collectionModal, { size: 'lg', centered: true, windowClass: "bulk-order" }).result
      .then(
        (allocationCollectionId?: number) => {
          this.cartItem.allocationCollectionId = allocationCollectionId;
          this.performAddToCart();
        },
        () => { },)

  }

  selectColor(colorName: number) {
    const index = this.product.images.findIndex(color => color.name.toLowerCase() === this.cartItem.selectedColour);
    if (index !== -1) {
      this.thumbnailCarousel.slickGoTo(index);
    }
  }

  finalPriceWithGst() {
    if (!this.product || !this.product.price) {
      return null;
    }

    let finalPrice = exactMath.sub(
      this.product.price,
      this.product.subsidyAmount || 0
    );

    if (finalPrice > 0) {
      const gstRate = 0.1; // 10% GST rate
      const finalPricegST = finalPrice * (1 + gstRate);
      return this.utils.twoDecimalPlacesString(finalPricegST);
    }

    return null;
  }

  isSelectedImage(image: any): boolean {
    return this.cartItem.selectedColour === image;
  }

  /**
 * @description Removes the indiciated decoration from the cartItem
 */
  public removeDecorationAtIndex(index: number) {
    this.cartItem.selectedDecorations?.splice(index, 1);

    this.setSelectableDecorations();
  }

  performAddToCart() {
    this.isLoading = true;
    const addItemSubscription = this.cartService.addItemToCart(this.cartItem);
    if (addItemSubscription) {
      addItemSubscription
        .pipe(
          debounceTime(200),
          takeUntil(this.unsubscribe$)
        )
        .subscribe(() => {
          this.notification.success('Adding Item Complete', 'Adding item to cart was successful.');
          this.cartItem.allocationCollectionConsumption = undefined;
          this.cartItem.allocationCollectionId = undefined;
          this.getCartItems();
          this.isLoading = false;
        });
    }
  }

  public bulkModel(content) {
    this.modalService.open(content, {
      size: 'lg', centered: true, backdrop: true, windowClass: 'bulk-order'
    }).result.then(() => {
    }, () => { });
  }

  /**
  * @description Handles an outcome from the bulk order modal to add cart items to the cart
  * @param {NewCartItem[]} items
  * @returns {void}
  */
  addBulkOrder(items: NewCartItem[]): void {
    this.notification.warn('Adding Item to Cart', 'Adding your new Cart Item.');
    const addToCartObservables = items.map(item => this.cartService.addItemToCart(item));

    forkJoin(addToCartObservables)
      .pipe(
        debounceTime(200),
        takeUntil(this.unsubscribe$)
      ).subscribe({
        next: () => {
          this.notification.success('Adding Item Complete', 'Adding item to cart was successful.');
          this.collectionsService.loadCollections();
          this.modalService.dismissAll();
        },
        error: (error) => {
          console.error('Error adding items to cart:', error);
        }
      });
  }

  setPackSize(name: string) {
    const pack = this.product.packSizes.find(pack => pack.name == name);
    if (!pack) return;
    this.cartItem.selectedPack = pack;
  }

  fetchImages(colour: string) {
    return this.product.images.find(img => img.name.toLowerCase() == colour.toLowerCase())?.url;
  }

  fetchProductAllocation() {
    this.globals.withSession(() => {

      if (this.globals.customer?.id && this.product.id) {
        this.customerId = this.globals.customer.id;
        let collectionData = this.collectionsService.getCollectionAllocationsForProduct(this.product.id, this.customerId);
        collectionData
          .pipe(
            takeUntil(this.unsubscribe$)
          ).subscribe(result => {
            this.collectionResult = result;
            if (this.collectionResult && this.collectionResult.count && this.collectionResult.rows.some(item => !!item.allocationAvailable)) {
              const result = this.collectionResult;
              this.allocationCollections = [];

              result.rows
                .filter(row => row.products.find(collectionProduct => collectionProduct.productId === this.product.id))
                .forEach(allocationCollection => {
                  if (allocationCollection.allocationAvailableDistribution) {
                    allocationCollection.allocationAvailableDistribution.forEach(distribution => {
                      const namedCollection = assign(distribution, {
                        name: allocationCollection.name
                      });

                      this.allocationCollections.push(namedCollection);
                    })
                  }
                });

              this.allocationCollections = this.allocationCollections.filter(collection => !!collection.count);
            }
          });

        this.getCartItems();
      }
    });
  }

  getCartItems() {
    this.cartService.getCartItems(this.customerId)
      .pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe((items) => {
        for (let i of items) {
          let productImage = i.product.images.find(image => image.name === i.variation.colour)
          i.variation.imageUrl = productImage ? productImage.url : i.product.imageUrl as string | undefined;
        }
        this.existingCartItems = items;
      })
  }

  generateGradient(colorString: string): string {
    if (colorString) {
      const colors = colorString.split('/');
      if (colors.length >= 2) {
        const firstColor = colors[0].trim();
        const secondColor = colors[1].trim();
        return `linear-gradient(0deg, ${firstColor} 50%, ${secondColor} 50%)`;
      } else if (colorString) {
        return colorString;
      } else {
        return 'default';
      }
    } else {
      return 'default';
    }
  }

  getUnitPrice(item: ServerCartItem) {
    const cartItems = item.asNewCartItem();
    return cartItems.getUnitPrice();
  }

  /**
  * @description Removes this item from the cart
  */
  deleteCartItem(item: ServerCartItem) {
    this.isRemoving = true;
    this.deleteItemId = item.id;
    this.cartService.deleteCartItem(this.customerId, item.id)
      .pipe(
        debounceTime(200),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.notifications.success('Saved', 'Removed Cart Item');
        this.isRemoving = false;
        this.deleteItemId = null;
        this.fetchProductAllocation();
      });
  }

  findTotalAllocation(data: (IShortAllocationCollection & { name: string })) {
    let sumAllocationCollectionConsumption = 0;

    this.existingCartItems.forEach(item => {
      if (item.allocationCollectionId === data.id) {
        if (item.allocationCollectionConsumption) {
          sumAllocationCollectionConsumption += item.allocationCollectionConsumption;
        }
      }
    });
    return sumAllocationCollectionConsumption + data.count;
  }

  findResetTime(data: (IShortAllocationCollection & { name: string })) {
    if (this.session.$userData.value?.customerUsers && this.session.$userData.value.customerUsers[0]) {
      const user = this.session.$userData.value?.customerUsers[0];
      // if (this.session.$userData.value && this.session.$userData.value.isAdmin) {
      //   this.isAdmin = true;
      // }
      if (user.userAllocations) {
        for (const allocation of user.userAllocations) {
          if (allocation.allocationCollections) {
            for (const collection of allocation.allocationCollections) {
              if (collection.id === data.id) {
                return this.customerUserService.describeRunSequence(allocation);
              }
            }
          }
        }
      }
    }
  }

  finalPrice(): string | null {
    if (!this.product || !this.product.price) {
      return null;
    }

    const finalPrice = exactMath.sub(
      this.product.price,
      this.product.subsidyAmount || 0
    );

    if (finalPrice > 0) {
      return this.utils.twoDecimalPlacesString(finalPrice);
    }

    return null;
  }

  checkAdmin() {
    if (!this.session.$userData.value) {
      return false;
    }

    if (this.session.$userData.value.isAdmin) {
      return false;
    }

    return true;
  }

}
