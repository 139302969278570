import { Component, ViewChild } from '@angular/core';
import { ICollection, ICollectionCustomer } from '../../model/collection.model';
import { NewProduct } from '../../model/ddb.model';
import { IQueryFilter, QueryResult } from '../../model/query.filter.class';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../../services/customer.service';
import { CollectionService } from '../../services/collection.service';
import { has } from 'lodash';
import { ProductService } from '../../services/product.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-collection-edit',
  templateUrl: './collection-edit.component.html',
  styleUrls: []
})
export class CollectionEditComponent {
  model: ICollection = {
    name: '',
    customers: [],
    products: [],
    accessMode: 1
  };

  customerSelectOptions: { id: string, text: string }[];

  /**
   * @description Temporary holding for the selected customer option
   */
  selectedCustomerOption: { id: string, text: string };

  /** Temporary storage for products before they are added to the model */
  selectedProducts: Array<NewProduct> = [];


  /** Temporarily store the last page of decoration data */
  productResult: QueryResult<NewProduct> = new QueryResult();
  activeClassId: number = 1;
  @ViewChild('customer') selectCustomer: NgSelectComponent;
  private searchTerms: Subject<string> = new Subject<string>();

  public customerQuery = new IQueryFilter({
    limit: 10,
    sortBy: 'name'
  });

  @ViewChild('product') selectProduct: NgSelectComponent;
  private searchTermProduct: Subject<string> = new Subject<string>();

  public productQuery = new IQueryFilter({
    limit: 10,
    sortBy: 'name'
  });
  public noCustomerFoundText: string;
  public noProductFoundText: string;
  customerId: string | null;
  isCustomer: boolean;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService,
    private collectionService: CollectionService,
    private productService: ProductService,
    private notification: NotificationsService
  ) {
  }

  ngOnInit() {
    this.initProductSelect();
    this.handleCustomerListGet();
    this.search();
    this.productSearch();
    this.route.params.subscribe(params => {
      if (has(params, 'id')) {
        this.model.id = params.id;

        this.loadModel(this.model.id);
      }
    });

    this.customerId = this.route.snapshot.queryParamMap.get('customerId');
    if (this.customerId) {
      this.isCustomer = true;
      this.model.customers.push({
        customerId: +this.customerId,
        name: ''
      });
    }
  }

  dumpModel = () => {
    console.log(JSON.stringify(this.model, null, 2));
  };

  /**
   * @description Turns response from Customer Get into options for select2
   *
   * @param {QueryResult<HasId & NewCustomer>} result
   */
  handleCustomerListGet(isScroll: boolean = false) {
    this.noCustomerFoundText = "Fetching....";
    if (isScroll) {
      this.customerQuery.limit = this.customerQuery.limit + 10;
    }

    this.customerService.list(this.customerQuery)
      .subscribe((result) => {
        if (result.rows.length == 0) {
          this.noCustomerFoundText = "No Customer found";
        }
        this.customerSelectOptions = [
          ...result.rows.map(customer => ({ id: customer.id.toString(), text: customer.name }))
        ];
      });
  }

  onCustomerSearch(searchTerm: { term: string }) {
    this.searchTerms.next(searchTerm.term);
  }

  search() {
    this.searchTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      this.customerQuery.filter.name = { $like: '%' + searchTerm + '%' };
      this.handleCustomerListGet();
    });
  }

  productSearch() {
    this.searchTermProduct.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      this.productQuery.filter['$or'] = {
        name: { $like: '%' + searchTerm + '%' },
        code: { $like: '%' + searchTerm + '%' },
        uri: { $like: '%' + searchTerm + '%' },
        description: { $like: '%' + searchTerm + '%' }
      }
      this.initProductSelect();
    });
  }

  onProductSearch(searchTerm: { term: string }) {
    this.searchTermProduct.next(searchTerm.term);
  }

  /**
   * @description Takes the temporarily stored selected customer ID and adds it to the product customers
   *              which will grant explicit access to the product to the customer in question, and if
   *              entered, the custom price for this product for the customer in question
   */
  addSelectedCustomer = () => {
    if (!this.selectCustomer.selectedValues.length)
      return;

    if (!this.model.customers)
      this.model.customers = [];

    this.selectCustomer.selectedValues.forEach(selectedCustomer => {
      if (!this.model.customers.find(productCustomer => productCustomer.customerId === +selectedCustomer.id)) {
        this.model.customers.push({
          customerId: (+selectedCustomer.id * 1),
          name: selectedCustomer.text
        });
      }
    });

    this.disableSelectChange = true;

    this.selectCustomer.clearModel();
    this.selectCustomer.searchTerm = '';
    this.selectCustomer.close();
    this.onClear('customer');

    setTimeout(() => {
      this.disableSelectChange = false;
    }, 0);
  };

  onClear(type: string) {
    if (type == 'customer' && this.customerQuery.filter.name) {
      delete this.customerQuery.filter.name;
      this.handleCustomerListGet();
    } else if (this.productQuery.filter.$or) {
      delete this.productQuery.filter.$or;
      this.initProductSelect();
    }
  }

  /**
   * @description Removes the selected productCustomer from the list productCustomers and re-indexes the
   *              productCustomer array to maintain the existing order
   *
   * @param modelCustomer
   */
  removeCustomer(modelCustomer: ICollectionCustomer) {
    for (let i = 0; i < this.model.customers.length; i++) {
      if (this.model.customers[i].customerId === modelCustomer.customerId) {
        this.model.customers.splice(i, 1);
        break;
      }
    }
  }

  /**
   * @description Validate and persist the product in the server, ignoring validating for Draft Products
   */
  saveModel() {
    if (this.isCustomer ? this.activeClassId == 2 : this.activeClassId == 3) {
      if (!this.model.name || !this.model.name.trim().length) {
        this.activeClassId = 1;
        this.notification.warn('Add Collection', 'Collection name is Required');
        return;
      }
      let handler = this.model.id ?
        this.collectionService.update(this.model.id, this.model) :
        this.collectionService.create(this.model);

      handler.subscribe(() => {
        if (!this.model.id) {
          this.getRoute();
        }
      },
        err => {
          let errMsg = "Unknown Error Saving Collection";
          if (err.status === 400 || err.status === 406) {
            console.log(err);
            if (has(err, 'error')) {
              if (has(err.error, 'errors'))
                errMsg = err.error.errors;
              if (has(err.error, 'message'))
                errMsg = err.error.message;
            }
          }
        });
    } else {
      this.activeClassId = this.activeClassId + 1;
    }
  }

  getRoute() {
    if (this.customerId) {
      this.router.navigate(['manage/customer/edit', this.customerId], { queryParams: { activeTab: 3 } });
    } else {
      this.router.navigate(['/manage/collections']);
    }
  }

  /**
   * @description Loads the existing product data from the database
   */
  loadModel(id: number | undefined) {
    if (id) {
      this.collectionService.get(id)
        .subscribe((collection: ICollection) => {
          this.model = collection;

          this.model.products = (this.model.products || []).filter(collectionProduct => collectionProduct.product !== null);
        });
    }
  }

  /**
   * @description Done due to search results changing the available selection options rendering it impossible to find
   * the decorations details
   */
  selectProducts() {
    if (!this.selectProduct.selectedValues.length)
      return;

    let items = this.selectProduct.selectedValues;
    let selectedIds: number[] = items.map(item => Number(item));

    for (let i = this.selectedProducts.length - 1; i >= 0; i--) {
      const productId = this.selectedProducts[i].id;
      if (productId !== undefined && !selectedIds.includes(productId)) {
        this.selectedProducts.splice(i, 1);
      }
    }

    selectedIds.forEach(id => {
      let existing = this.selectedProducts.find(d => d.id == id);
      if (!existing) {
        let product = this.productResult.rows.find(d => d.id == id);
        if (product) {
          this.selectedProducts.push(product);
        }
      }
    });
  }
  disableSelectChange = false;
  onSelectChange(select: any) {
    if (this.disableSelectChange) {
      return;
    }
    setTimeout(() => {
      select.open();
    });
  }

  /**
   * @description Appends all the decorations in the decoration buffer (selectedProducts) to the model
   */
  addProduct() {
    this.selectedProducts.forEach(product => {
      let existing = this.model.products.find(existing => existing.productId === product.id);

      if (existing) {
        return;
      }

      if (product.id) {
        this.model.products.push({
          productId: product.id,
          product: product
        });
      }
    });
    this.disableSelectChange = true;
    this.selectedProducts = [];

    this.selectProduct.clearModel();
    this.selectProduct.searchTerm = '';
    this.selectProduct.close();

    this.onClear('product');
    setTimeout(() => {
      this.disableSelectChange = false;
    }, 0);
  }

  removeProduct(idString: string) {
    const productId = Number(idString);
    for (let i = 0; i < this.model.products.length; i++) {
      if (this.model.products[i].productId === productId) {
        this.model.products.splice(i, 1);
        break;
      }
    }
  }

  initProductSelect = (isScroll: boolean = false) => {
    this.noProductFoundText = "Fetching..."
    if (isScroll) {
      this.productQuery.limit = this.customerQuery.limit + 10;
    }
    this.productService.list(this.productQuery)
      .subscribe(queryResult => {
        this.productResult = queryResult;
        if (queryResult.rows.length == 0) {
          this.noProductFoundText = "No Product found"
        }
      });
  }

  setActive(id: number) {
    this.activeClassId = id;
  }
}
