<div class="main-right-content">
	<div class="manageUser">
		<div class="manage-user-list">
			<div class="tab-content" id="pills-tabContent">
				<div class="tab-pane fade show active" id="pills-UserInformation" role="tabpanel"
					aria-labelledby="pills-UserInformation-tab" tabindex="0">
					<form>
						<div class="edit-users" *ngIf="customerUser && customerUser.user">
							<div class="edit-card card">
								<div class="card-head">
									<h4 class="head-title">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path
													d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75"
													fill="white" />
												<path
													d="M10 9.25C11.6569 9.25 13 7.90685 13 6.25C13 4.59315 11.6569 3.25 10 3.25C8.34315 3.25 7 4.59315 7 6.25C7 7.90685 8.34315 9.25 10 9.25Z"
													fill="white" />
												<path
													d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
													stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											</svg>
										</span>
										User details
									</h4>
								</div>
								<div class="card-body">
									<div class="row">
										<div class="col-12">
											<h5 class="form-title">PERSONAL DETAILS</h5>
										</div>
										<div *ngIf="!isGuest" class="col-12 col-lg-6">
											<div class="form-group">
												<label for="firstName" class="form-label">First name <span class="text-danger">*</span></label>
												<input type="text" class="form-control" id="firstName" name="firstName"
													[(ngModel)]="customerUser.user.firstName" appInputMaxLength="120" />
											</div>
										</div>
										<div *ngIf="!isGuest" class="col-12 col-lg-6">
											<div class="form-group">
												<label for="lastName" class="form-label">Last name <span class="text-danger">*</span></label>
												<input type="text" class="form-control" id="lastName" name="lastName"
													[(ngModel)]="customerUser.user.lastName" appInputMaxLength="120" />
											</div>
										</div>
										<div class="col-12 col-lg-6">
											<div class="form-group">
												<label for="emailAddress" class="form-label">Email address <span
														class="text-danger">*</span></label>
												<input type="email" class="form-control" id="emailAddress" name="email"
													[(ngModel)]="customerUser.user.email" />
											</div>
										</div>
										<div *ngIf="!isGuest" class="col-12 col-lg-6">
											<div class="form-group">
												<label for="contactNumber" class="form-label">Contact number <span
														*ngIf="requiredField.startDate">(Optional)</span></label>
												<input type="number" class="form-control" id="contactNumber" name="contact"
													[(ngModel)]="customerUser.user.contactNumber"
													[attr.required]="requiredField.contact ? true : null" />
											</div>
										</div>
										<div *ngIf="!isGuest" class="col-12">
											<h5 class="form-title">BUSINESS DETAILS</h5>
										</div>
										<div *ngIf="!isGuest" class="col-12 col-lg-6">
											<div class="form-group">
												<label for="accountNumber" class="form-label">Cost account number <span
														*ngIf="requiredField.costAccount" class="text-danger">*</span></label>
												<input type="text" class="form-control" name="cost" id="accountNumber"
													[(ngModel)]="customerUser.costAccount"
													[attr.required]="requiredField.costAccount ? true : null" />
											</div>
										</div>
										<div *ngIf="!isGuest" class="col-12 col-lg-6">
											<div class="form-group">
												<label for="employeeNumber" class="form-label">Employee number <span
														*ngIf="requiredField.employeNumber" class="text-danger">*</span></label>
												<input type="text" class="form-control" id="employeeNumber" name="employe"
													[(ngModel)]="customerUser.employeeNumber"
													[attr.required]="requiredField.employeNumber ? true : null" appInputMaxLength="120" />
											</div>
										</div>
										<div *ngIf="!isGuest" class="col-12 col-lg-6">
											<div class="form-group">
												<label for="jobTitle" class="form-label">Job title <span *ngIf="requiredField.jobTittle"
														class="text-danger">*</span></label>
												<input type="text" class="form-control" id="jobTitle" [(ngModel)]="customerUser.user.jobTitle"
													[attr.required]="requiredField.jobTittle ? true : null" name="jobTittke"
													appInputMaxLength="120" />
											</div>
										</div>
										<div class="col-12 col-lg-6">
											<div class="form-group">
												<label for="businessUnit" class="form-label">Business unit <span
														*ngIf="requiredField.businessUnit" class="text-danger">*</span></label>
												<ng-select name="business" *ngIf="businessUnit" id="businessUnit" #business
													[items]="businessUnit" bindLabel="name" [virtualScroll]="true" [searchable]="true"
													bindValue="id" (change)="businessUnitChange()" [ngModel]="customerUser.businessUnitId"
													[attr.required]="requiredField.businessUnit ? true : null">
												</ng-select>
											</div>
										</div>
										<div class="col-12 col-lg-6" *ngIf="userRoles">
											<div class="form-group">
												<label for="userRole" class="form-label">User role <span class="text-danger">*</span></label>
												<ng-select name="role" id="userRole" #role [items]="userRoles" bindLabel="name"
													[virtualScroll]="true" [searchable]="true" bindValue="id" (search)="searchUserRole($event)"
													(change)="userRoleChange()" [ngModel]="customerUser.userRoleId">
												</ng-select>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="form-group">
												<label for="Dates" class="form-label">Start date <span *ngIf="requiredField.startDate"
														class="text-danger">*</span></label>
												<input #currentDate type="date" class="form-control" id="Dates" placeholder="dd.mm.rrrr"
													(change)="setStartDate(currentDate.value)" [value]="getStartDate()"
													[attr.required]="requiredField.startDate ? true : null" />
												<div id="dateRequired" class="form-text">
													Date will be assumed to be (00:00am AEST).
												</div>
											</div>
										</div>

										<div class="col-12 col-lg-6" *ngIf="
												customerUsers &&
												customerUsers.rows.length &&
												(canHaveApprover ||
												enableUsersRequireApproval)
											">
											<div class="form-group">
												<label for="authorizedApprover" class="form-label">Authorized approver</label>
												<select #selectCustomer class="form-select" aria-label="Default select example"
													id="authorizedApprover" (change)="
														customerUser.approverId = +selectCustomer.value
													">
													<option>Select Approver</option>
													<option *ngFor="let data of customerUsers.rows" [value]="data.user.id" [selected]="
															customerUser &&
															data.user.id === customerUser.approverId
														">
														{{ data.user.firstName + " " + data.user.lastName }}
													</option>
												</select>
											</div>
										</div>
										<div class="col-12" *ngIf="canHaveApprover || enableUsersRequireApproval">
											<h5 class="form-title">ORDERS</h5>
										</div>
										<div class="col-12 col-lg-6" *ngIf="canHaveApprover || enableUsersRequireApproval">
											<div class="form-group">
												<label for="requiredApprover" class="form-label">Required approval? Orders Over</label>
												<input type="number" class="form-control" id="requiredApprover" placeholder="$0.00"
													aria-describedby="requiredHelp" name="approval" [(ngModel)]="customerUser.approvalLimit"
													name="approve" />
												<div id="requiredHelp" class="form-text">
													Default is zero if no approval needed.
												</div>
											</div>
										</div>
										<div class="col-12 col-lg-6"
											*ngIf="(canHaveApprover || enableUsersRequireApproval) && canHaveAllocation">
											<div class="form-group form-check">
												<label for="approval" class="form-label">Allocations Require Approval
													<span class="form-text fs-5 d-block mt-1">
														Approvals are sent when approvals are enabled at
														EITHER customer or user level.
													</span>
												</label>
												<input type="checkbox" name="date" class="form-check-input" id="approval"
													aria-describedby="dateRequired" [value]="true" name="enableOrdersRequireApproval"
													[(ngModel)]="customerUser.enableOrdersRequireApproval" />
											</div>
										</div>
										<custom-user-field *ngIf="customerUserId" [customerId]="customerId"
											[customerUserId]="customerUserId" #customUserFieldForm></custom-user-field>
									</div>
								</div>
							</div>
							<div *ngIf="customerUser.user.shippingDetails && !isGuest">
								<div class="edit-card card">
									<div class="card-head">
										<h4 class="head-title">
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
													<path
														d="M14.875 16.75C15.9105 16.75 16.75 15.9105 16.75 14.875C16.75 13.8395 15.9105 13 14.875 13C13.8395 13 13 13.8395 13 14.875C13 15.9105 13.8395 16.75 14.875 16.75Z"
														stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
													<path
														d="M5.125 16.75C6.16053 16.75 7 15.9105 7 14.875C7 13.8395 6.16053 13 5.125 13C4.08947 13 3.25 13.8395 3.25 14.875C3.25 15.9105 4.08947 16.75 5.125 16.75Z"
														stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
													<path d="M13 7H16L18.25 9.25V13H13V7Z" stroke="#272835" stroke-width="1.5"
														stroke-linecap="round" stroke-linejoin="round" />
													<path d="M13 3.25H1.75V13H13V3.25Z" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
														stroke-linejoin="round" />
												</svg>
											</span>
											Home Address
										</h4>
									</div>
									<div class="card-body">
										<div class="row">
											<div class="col-12">
												<div class="form-group form-check">
													<input type="radio" class="form-check-input" name="address" value="company" id="deliverCheck1"
														[(ngModel)]="companyAddress" />
													<label class="form-check-label" for="deliverCheck1">Deliver to company address
													</label>
												</div>
											</div>
											<div class="col-12">
												<div class="form-group">
													<label for="companyAddress" class="form-label">Company Address</label>
													<select #selectAddress [disabled]="companyAddress !== 'company'" class="form-select"
														aria-label="Default select example" id="companyAddress"
														(change)="changsAddress(selectAddress.value)">
														<option selected>Select Address</option>
														<option *ngFor="let address of customerAddress"
															[selected]=" this.customerUser.user && this.customerUser.user.shippingDetailsId == address.id"
															[value]="address.id">
															{{address.addressName}}
														</option>
													</select>
												</div>
											</div>

											<div class="col-12">
												<div class="form-group form-check">
													<input type="radio" class="form-check-input" name="address" value="own" id="deliverCheck2"
														[(ngModel)]="companyAddress" (change)="checkAddressType()" />
													<label class="form-check-label" for="deliverCheck2">Deliver to my own address
													</label>
												</div>
											</div>

											<!-- <div class="row" *ngIf="companyAddress == 'own'"> -->
											<div class="col-12">
												<div class="form-group">
													<label for="Address" class="form-label">Name <span *ngIf="requiredField.address"
															class="text-danger">*</span></label>
													<input type="text" class="form-control" id="Address" [(ngModel)]="
																customerUser.user.shippingDetails.addressName
															" name="addressName" [disabled]="companyAddress !== 'own'" />
												</div>
											</div>
											<div class="col-12 col-lg-6">
												<div class="form-group">
													<label for="AddressLine1" class="form-label">Address Line 1 <span
															*ngIf="requiredField.address" class="text-danger">*</span></label>
													<input type="text" class="form-control" id="AddressLine1" [(ngModel)]="
																customerUser.user.shippingDetails.streetAddress
															" name="streetAddress" [disabled]="companyAddress !== 'own'" />
												</div>
											</div>
											<div class="col-12 col-lg-6">
												<div class="form-group">
													<label for="AddressLine2" class="form-label">Address Line 2 <span
															*ngIf="requiredField.address" class="text-danger">*</span></label>
													<input type="text" class="form-control" id="AddressLine2" [(ngModel)]="
																customerUser.user.shippingDetails.streetAddress2
															" name="streetAddress2" [disabled]="companyAddress !== 'own'" />
												</div>
											</div>
											<div class="col-12 col-lg-6">
												<div class="form-group">
													<label for="subrub" class="form-label">Suburb <span *ngIf="requiredField.address"
															class="text-danger">*</span></label>
													<input type="text" class="form-control" id="subrub" [(ngModel)]="
																customerUser.user.shippingDetails.suburb
															" name="subrub" [disabled]="companyAddress !== 'own'" />
												</div>
											</div>
											<div class="col-12 col-lg-6">
												<div class="form-group">
													<label for="Country" class="form-label">Country <span *ngIf="requiredField.address"
															class="text-danger">*</span></label>
													<input type="text" class="form-control" id="Country" [(ngModel)]="
																customerUser.user.shippingDetails.country
															" name="country" [disabled]="companyAddress !== 'own'" />
												</div>
											</div>
											<div class="col-12 col-lg-6">
												<div class="form-group">
													<label for="City" class="form-label">City</label>
													<input type="text" class="form-control" id="City"
														[(ngModel)]="customerUser.user.shippingDetails.city" name="city"
														[disabled]="companyAddress !== 'own'" />
												</div>
											</div>
											<div class="col-12 col-lg-6">
												<div class="form-group">
													<label for="State" class="form-label">State <span *ngIf="requiredField.address"
															class="text-danger">*</span></label>
													<input type="email" class="form-control" id="State" [(ngModel)]="
																customerUser.user.shippingDetails.region
															" name="region" [disabled]="companyAddress !== 'own'" />
												</div>
											</div>
											<div class="col-12 col-lg-6">
												<div class="form-group">
													<label for="postalCode" class="form-label">Postal Code <span *ngIf="requiredField.address"
															class="text-danger">*</span></label>
													<input type="number" class="form-control" id="postalCode" [(ngModel)]="
																customerUser.user.shippingDetails.postalCode
															" name="postalCode" [disabled]="companyAddress !== 'own'" />
												</div>
											</div>
											<div class="col-12 col-lg-6">
												<div class="form-group form-check">
													<label for="handling" class="form-label"> Small Order Fee Free</label>
													<input type="checkbox" name="date" class="form-check-input" id="handling"
														aria-describedby="dateRequired" [value]="true" name="isFreeHandling" [(ngModel)]="
															customerUser.user.shippingDetails.isFreeHandling
														" [disabled]="companyAddress !== 'own'" />
													<div id="handling" class="form-text">
														Shipping to this address is never charged the
														Small Order Fee.
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- </div> -->

								<div class="edit-card card">
									<div class="card-head">
										<h4 class="head-title">
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
													<path
														d="M12.025 5.72578C11.8876 5.86598 11.8106 6.05446 11.8106 6.25078C11.8106 6.44709 11.8876 6.63558 12.025 6.77578L13.225 7.97578C13.3652 8.1132 13.5537 8.19017 13.75 8.19017C13.9463 8.19017 14.1348 8.1132 14.275 7.97578L17.1025 5.14828C17.4796 5.98167 17.5938 6.9102 17.4299 7.81014C17.2659 8.71007 16.8315 9.53866 16.1847 10.1855C15.5379 10.8323 14.7093 11.2667 13.8094 11.4306C12.9094 11.5946 11.9809 11.4804 11.1475 11.1033L5.96501 16.2858C5.66665 16.5841 5.26197 16.7518 4.84001 16.7518C4.41806 16.7518 4.01338 16.5841 3.71501 16.2858C3.41665 15.9874 3.24902 15.5827 3.24902 15.1608C3.24902 14.7388 3.41665 14.3341 3.71501 14.0358L8.89751 8.85328C8.52039 8.01989 8.4062 7.09135 8.57017 6.19142C8.73414 5.29149 9.16848 4.46289 9.8153 3.81607C10.4621 3.16924 11.2907 2.7349 12.1907 2.57094C13.0906 2.40697 14.0191 2.52115 14.8525 2.89828L12.0325 5.71828L12.025 5.72578Z"
														fill="url(#paint0_radial_0_16351)" fill-opacity="0.5" />
													<path
														d="M12.025 5.72578C11.8876 5.86598 11.8106 6.05446 11.8106 6.25078C11.8106 6.44709 11.8876 6.63558 12.025 6.77578L13.225 7.97578C13.3652 8.1132 13.5537 8.19017 13.75 8.19017C13.9463 8.19017 14.1348 8.1132 14.275 7.97578L17.1025 5.14828C17.4796 5.98167 17.5938 6.9102 17.4299 7.81014C17.2659 8.71007 16.8315 9.53866 16.1847 10.1855C15.5379 10.8323 14.7093 11.2667 13.8094 11.4306C12.9094 11.5946 11.9809 11.4804 11.1475 11.1033L5.96501 16.2858C5.66665 16.5841 5.26197 16.7518 4.84001 16.7518C4.41806 16.7518 4.01338 16.5841 3.71501 16.2858C3.41665 15.9874 3.24902 15.5827 3.24902 15.1608C3.24902 14.7388 3.41665 14.3341 3.71501 14.0358L8.89751 8.85328C8.52039 8.01989 8.4062 7.09135 8.57017 6.19142C8.73414 5.29149 9.16848 4.46289 9.8153 3.81607C10.4621 3.16924 11.2907 2.7349 12.1907 2.57094C13.0906 2.40697 14.0191 2.52115 14.8525 2.89828L12.0325 5.71828L12.025 5.72578Z"
														stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
													<defs>
														<radialGradient id="paint0_radial_0_16351" cx="0" cy="0" r="1"
															gradientUnits="userSpaceOnUse"
															gradientTransform="translate(10.3759 3.21073) rotate(90) scale(10.3339)">
															<stop stop-color="#8BD6EF" />
															<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
														</radialGradient>
													</defs>
												</svg>
											</span>
											Login security

											<span class="badge bg-alpha-25">2 factory method</span>
										</h4>
										<div class="form-check form-switch">
											<label class="form-check-label" for="enabledSwitch">{{
												secureLoginType !== "none" ? "Enabled" : "Disabled"
												}}</label>
											<input class="form-check-input" type="checkbox" role="switch" id="enabledSwitch"
												[checked]="secureLoginType !== 'none'" (click)="checkSecureLoginType()" />
										</div>
									</div>
									<div class="card-body" *ngIf="secureLoginType !== 'none'">
										<div class="auths">
											<div class="form-group form-check">
												<label class="form-check-label" [class.active]="secureLoginType == 'mail'"
													for="Authentication1">
													E-mail
													<span><input class="form-check-input" type="radio" name="Authentications" id="Authentication1"
															[checked]="secureLoginType == 'mail'" (click)="checkSecureLoginType('mail')" />
													</span>
												</label>
											</div>
											<div class="form-group form-check">
												<label class="form-check-label" [class.active]="secureLoginType == 'app'" for="Authentication2">
													Authenticator app
													<span>
														<input class="form-check-input" type="radio" name="Authentications" id="Authentication2"
															[checked]="secureLoginType == 'app'" (click)="checkSecureLoginType('app')" />
													</span>
												</label>
											</div>
										</div>
										<div class="auth-qr" *ngIf="tfaBlock">
											<div class="auth-img">
												<img src="{{ qrImage }}" alt="qrImage" />
											</div>
											<div class="form-text mt-3">
												{{ secretCode }}
											</div>
										</div>
										<div class="row" *ngIf="tfaVerifyBlock">
											<div class="col-12 col-lg-9">
												<div class="form-group">
													<label *ngIf="!tfaVerificationSuccess" for="secureCode" class="form-label">Secure Code
													</label>
													<input type="number" class="form-control" name="otp" [(ngModel)]="otp"
														*ngIf="!tfaVerificationSuccess" id="secureCode" placeholder="" />
													<div class="form-text text-danger position-absolute"
														*ngIf="wrongOtp && !tfaVerificationSuccess">
														Secure Code Incorrect.
													</div>
												</div>
											</div>
											<div class="col align-self-end">
												<div class="form-group" *ngIf="!tfaVerificationSuccess">
													<button class="btn btn-primary w-100" type="button" (click)="verifyOtp()">
														Validate
													</button>
												</div>
											</div>
											<div class="col-12">
												<ul class="list-view">
													<li>
														<p class="form-text">
															Using an Authenticator app Such as Google
															Authenticator, scan the above QR code.
														</p>
													</li>
													<li>
														<p class="form-text">
															To ensure your 2 Factor Authentication Code is
															Working Correctly, please enter a vaild code now.
														</p>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="col-lg-6">
			<button class="btn btn-primary w-sm-100" (click)="createUser()">
				Submit
			</button>
		</div>
	</div>
</div>