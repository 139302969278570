import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { CartService } from '../../services/cart.service';
import { CartAttrs, ServerCartItem } from '../../model/cart.model';
import { GlobalApi } from '../../api/global.api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { SessionApi } from '../../api/session.api';
import { CartApi } from '../../api/cart.api';
import { Utils } from '../../util/utils';
import { UnleashedService } from '../../services/unleashed.service';
import { ShippingDetailsClass } from '../../model/shippingDetail.model';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: []
})
export class OrderDetailComponent implements OnInit {
  public enableShipping = false;
  orderId: number;
  order: any;
  public trackingDetail = '';
  public placedByUser;
  public trackingDetailUrl = '';
  public trackingLink = '';
  public shipmentDetailUrl = '';
  public orderUrl = '';
  public consumedAllocation = 0;
  showTrackingEdit: boolean = false;
  isShipment = false;
  isReOrder: boolean;
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private cartService: CartService,
    public globals: GlobalApi,
    private modalService: NgbModal,
    private notifications: NotificationsService,
    private cartApi: CartApi,
    public utils: Utils,
    private unleashedService: UnleashedService
  ) { }

  ngOnInit() {

    this.enableShipping = environment['enableShipping'];

    this.route.params.subscribe(params => {

      if (params.hasOwnProperty('id')) {
        this.orderId = Number(params['id']);
      } else {
        this.router.navigate(['/manage/orders']);
      }

      this.cartService.get(this.orderId)
        .subscribe(cart => {

          this.order = cart;
          this.trackingDetail = this.order.attrs.trackingDetail ? this.order.attrs.trackingDetail : '';
          this.trackingDetailUrl = this.order.attrs.trackingDetailUrl ? this.order.attrs.trackingDetailUrl : '';
          this.trackingLink = this.order.attrs.trackingLink ? this.order.attrs.trackingLink : '';
          this.placedByUser = this.order.attrs.PlacedByUser ? this.order.attrs.PlacedByUser : '';
          this.consumedAllocation = 0;
          if (this.order.transaction && this.order.transaction.consumedAllocation && this.order.transaction.consumedAllocation > 0 && this.order.attrs.status !== 'PENDING') {
            this.consumedAllocation = this.order.transaction.consumedAllocation;
          }

          this.trackingDetail = this.hasAttrs() ? this.order.attrs.trackingDetail : '';
          this.trackingLink = this.hasAttrs() ? this.order.attrs.trackingLink : '';

          this.fetchShipmentDetails();
          if (!this.globals.isAdmin) {
            this.orderUrl = `/account/orders`;
            this.shipmentDetailUrl = `/account/orders/${this.order.attrs.orderNumber}/shipments`;
          }
          else {
            this.orderUrl = `/manage/orders`;
            this.shipmentDetailUrl = `/manage/orders/${this.order.attrs.orderNumber}/shipments`;
          }
          // this.isNewShipmentAvailable = this.checkIsNewShipmentAvailable();
        },
          () => {
          });
    });
  }

  fetchShipmentDetails() {
    if (this.order.attrs.orderNumber) {
      this.unleashedService.getSalesShipmentsByOrderNumber(this.order.attrs.orderNumber).subscribe(salesShipments => {
        if (salesShipments.Items.length > 0) {
          this.isShipment = true;
        }
      });
    }
  }

  /**
 * Helper method to verify if there is a valid order loaded and it has attributes
 */
  hasAttrs = (): boolean => this.order && this.order.attrs;

  /**
* @description UI helper method for getting display text for the business unit column
*
* @param {CartAttrs} item
*/
  getBusinessUnitText = (item: CartAttrs): string | null => {
    if (item.businessUnit) {
      let result = item.businessUnit.name;
      if (item.businessUnit.code && item.businessUnit.code.length) {
        return result + ` (${item.businessUnit.code})`;
      }
      return result;
    }
    return 'Not Found';
  }

  /**
 * UI Helper method for getting the list of supporting documents on the current cart
 */
  public getSupportingDocuments = () =>
    this.order &&
      this.order.attrs &&
      this.order.attrs.supportingDocuments ?
      this.order.attrs.supportingDocuments :
      [];

  getTotalQuantity(item: ServerCartItem) {
    if (!item.packSize)
      return item.quantity;

    return item.quantity * item.packSize.itemCount;
  }

  showTrackingEditModal() {
    this.showTrackingEdit = !this.showTrackingEdit;
  }

  open(content) {
    this.modalService.open(content, {
      windowClass: 'bulk-order',
      modalDialogClass: 'modal-dialog-centered',
    });
  }

  saveTrackingInformation() {
    if (!this.trackingDetail.length) {
      this.notifications.error('Error Updating Order', 'Tracking id cannot be blank');
      return;
    }

    this.notifications.warn('Processing', 'Updating Order.');
    this.cartApi.saveCartAttrsAdmin(this.orderId, {
      trackingDetail: this.trackingDetail,
      trackingDetailUrl: this.trackingDetailUrl,
      trackingLink: this.trackingLink
    }).subscribe(() => {
      this.showTrackingEdit = false;
      this.order.attrs.trackingDetailUrl = this.trackingDetailUrl;
      this.order.attrs.trackingDetail = this.trackingDetail;
      this.order.attrs.trackingLink = this.trackingLink;

      this.notifications.success('Order Updated', 'Tracking details updated.');
    });
  }

  generateGradient(colorString: string): string {
    if (colorString) {
      const colors = colorString.split('/');
      if (colors.length >= 2) {
        const firstColor = colors[0].trim();
        const secondColor = colors[1].trim();
        return `linear-gradient(0deg, ${firstColor} 50%, ${secondColor} 50%)`;
      } else if (colorString) {
        return colorString;
      } else {
        return 'default';
      }
    } else {
      return 'default';
    }
  }

  /**
 * @description Reliably determines if the incoming cart has salesContactDetails for display
 * @returns {boolean}
 */
  hasSalesContactDetail(): boolean {
    if (!this.order || !this.order.attrs) return false;

    return !!this.order.attrs.salesContactEmail || !!this.order.attrs.salesContactEmail;
  }

  /**
 * @description UI helper method to determine if the order approval functionality should be presented to the user
 *
 * @param item
 */
  showOrderApproval(): boolean | null | number {
    return (
      this.order.attrs.status.toUpperCase() === 'APPROVAL'
      && (
        this.globals.User.IsAdmin
        || this.isApprovingManager(this.order)
      )
    );
  }

  /**
 * @description Approves an order
 *
 * @param item
 */
  approveOrder() {
    this.cartService.approveCart(this.order.id)
      .subscribe(() => {
        this.router.navigate(['/manage/orders']);
      },
        (err) => {
          console.log(err);
        })
  }

  onSaveCompleted() {
    this.router.navigate(['/manage/orders']);
  }

  /**
* Generates easily read display text for any given shipping details item
* @param details
* @param includeName
*/
  addressDisplayText(details: (ShippingDetailsClass & { displayName?: string }), includeName = false) {
    return (includeName && (details.addressName || details.displayName) ? (details.displayName || details.addressName) + ': ' : '') +
      (details.streetAddress ? details.streetAddress + ' ' : '') +
      (details.streetAddress2 ? details.streetAddress2 + ' ' : '') +
      (details.suburb ? details.suburb : '');
  }

  /**
 * @description UI Helper method to determine if the current user is the approving manager
 *
 * @param item
 */
  isApprovingManager(item) {
    if (!this.globals.User) return false;
    return item.attrs.approvedById && this.globals.User.actualUserId === item.attrs.approvedById;
  }

  fetchColor(status: string) {
    if (status === "PROCESSING") {
      return 'aqua';
    }
    if (status === "PENDING") {
      return 'aqua';
    }
    if (status === "COMPLETED") {
      return 'green';
    }
    if (status === "BACKORDERED") {
      return 'orange';
    }
    if (status === "APPROVAL") {
      return 'purple';
    }
    if (status === "DELETED") {
      return 'red';
    }
    if (status === "PLACED") {
      return 'blue';
    }
    if (status === "REJECTED") {
      return 'brown';
    }
  }

  /**
* @description UI helper method to determine if the order delete functionality should be presented to the user
*
* @param item
*/
  showDelete(): boolean | number {
    return (
      this.order.attrs.status.toUpperCase() === 'APPROVAL'
      && !this.globals.isAdmin
    );
  }

  showReOrder(): boolean {
    const statuses = ["COMPLETED", "DELETED", "REJECTED"];
    return statuses.includes(this.order.attrs.status);
  }

  reOrder() {
    this.isReOrder = true;
    this.cartService.reOrder(this.order.id).subscribe(() => {
      this.isReOrder = false;
      if (!this.globals.User.IsAdmin) {
        this.router.navigate(['/cart']);
      }
    });
  }

}
