import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IQueryFilter, QueryResult } from '../../model/query.filter.class';
import { EnumCreateParams, NewDecoration, NewProduct } from '../../model/ddb.model';
import { ProductService } from '../../services/product.service';
import { ActivatedRoute, NavigationEnd, Params, Router, RouterEvent } from '@angular/router';
import { SubscriptionGroup } from '../../util/subscriptionGroup';
import { Subject, debounceTime, takeUntil, tap } from 'rxjs';
import { CategoryService } from '../../services/category.service';
import { SessionApi } from '../../api/session.api';
import { CategoriesComponent } from '../categories/categories.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewCartItem } from '../../model/cart.model';
import { ICollection, IShortAllocationCollection } from '../../model/collection.model';
import { SecurityService } from '../../services/security.service';
import { isNullOrUndefined } from 'util';
import * as exactMath from "exact-math";
import { Utils } from '../../util/utils';
import { NotificationsService } from 'angular2-notifications';
import { CollectionService } from '../../services/collection.service';
import { assign, filter } from 'lodash';
import { CartService } from '../../services/cart.service';
import { GlobalApi } from '../../api/global.api';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html'
})
export class ProductViewComponent implements OnInit, OnDestroy {
  public hasAutomaticCollection: boolean = false;
  public collectionResult: QueryResult<ICollection> = new QueryResult();
  subscriptionGroup = new SubscriptionGroup();

  public isOpen: boolean = true;
  queryResult: QueryResult<NewProduct> = new QueryResult();
  queryParams: Params = {};
  public customerId: number;
  public totalCountActive: number = 0;
  public currentPageActive: number = 1;
  query: IQueryFilter = new IQueryFilter({
    sortBy: 'name',
    limit: 12
  });
  categories: EnumCreateParams[] = [];
  lastHandledUrl: string = '';
  @ViewChild(CategoriesComponent, { static: false })
  private categoryComponent!: CategoriesComponent;
  public product: NewProduct;
  cartItem: NewCartItem = new NewCartItem();
  public selectableDecorations: NewDecoration[] = [];
  public allocationCollections: (IShortAllocationCollection & { name: string })[] = [];
  public hasCartAccess: boolean;
  public isLoad: boolean = false;
  @ViewChild('collectionModal')
  public collectionModal: ElementRef;
  sortOptions = [{
    text: "name",
    field: "name"
  }, {
    text: "Price",
    field: "basePrice"
  }, {
    text: "Modified Date",
    field: "updatedAt"
  }, {
    text: "Modified By",
    field: "modified_by"
  }];
  private unsubscribe$ = new Subject<void>();
  private addToCartClick = new Subject<void>();
  public isLoading: boolean;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 500,
  };

  constructor(
    private productService: ProductService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private categoryService: CategoryService,
    private session: SessionApi,
    private modalService: NgbModal,
    private securityService: SecurityService,
    public utils: Utils,
    public notifications: NotificationsService,
    private collectionsService: CollectionService,
    private cartService: CartService,
    private globals: GlobalApi,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    // const pageNumber = localStorage.getItem('productPageNumber');
    this.route.queryParams.subscribe(queryparams => {
      this.currentPageActive = +queryparams['page'] || 1;

      this.productService.getFilterSort().subscribe(isOpen => {
        this.isOpen = isOpen;
      });
      this.monitorCartAccess();

      this.addToCartClick.pipe(
        debounceTime(1000),
        takeUntil(this.unsubscribe$)
      ).subscribe(() => {
        if (this.product.id) {
          if (this.cartItem.selectedDecorations && this.cartItem.selectedDecorations.length) {
            let hasDecorationOptionError = false;
            for (let i = 0; i < this.cartItem.selectedDecorations.length; i++) {
              const decoration = this.cartItem.selectedDecorations[i];
              if (!decoration.options || !decoration.options.length) { continue; }

              const requiredMissingData = decoration.options.filter(option => option.required && (!option.value || !option.value.length));
              if (requiredMissingData && requiredMissingData.length) {
                hasDecorationOptionError = true;
                break;
              }
            }

            if (hasDecorationOptionError) {
              this.notifications.warn('Processing', '"Ensure required options for all selected decorations have been correctly filled.');
              return;
            }
          }

          if (this.collectionResult && this.collectionResult.count && this.collectionResult.rows.some(item => !!item.allocationAvailable)) {
            if (this.allocationCollections.length === 0) {
              this.performAddToCart();
            } else {
              // Use the first matching collection
              const suitableCollections = this.allocationCollections.filter(collection => collection.count >= Number(this.cartItem.selectedQuantity));

              if (suitableCollections.length === 1) {
                this.cartItem.allocationCollectionId = suitableCollections[0].id;
                this.performAddToCart();
              } else if (suitableCollections.length > 1 && this.hasAutomaticCollection) {
                this.cartItem.allocationCollectionId = suitableCollections[0].id;
                this.performAddToCart();
              } else {
                this.openCollectionModal();
              }
            }
          } else {
            this.performAddToCart();
          }
        }
      })
    });
  }

  getSortText() {
    if (!this.query.sortBy)
      return undefined;

    const sortOption = this.sortOptions.find(option => option.field === this.query.sortBy);

    if (sortOption)
      return sortOption.text;
    return undefined;
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.productService.getSort().subscribe(sort => {
      if (sort) {
        this.query.sortBy = sort;
        this.getProducts();
      }
    });
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.queryParams = params;

        this.globals.withSession(() => {
          this.subscriptionGroup.add(
            this.categoryService.allCategories$
              .pipe(
                tap(res => this.handleCategoriesGet(res.rows))
              )
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(this.handleRouteParamsChange)
          );

          this.subscriptionGroup.add(
            this.router.events.subscribe((event: RouterEvent) => {
              if (event instanceof NavigationEnd) {
                if (window.location.href.match(/\/#\/products([\/\?].*)?$/)) {
                  this.handleRouteParamsChange();
                }
              }
            })
          );

          this.subscriptionGroup.add(
            this.activatedRoute.fragment.subscribe(() => {
              this.handleRouteParamsChange();
            })
          );
        });
      });

    this.session.$customerData
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(customer => {
        if (customer) {
          this.customerId = customer.id;
        }
      });
  };

  ngOnDestroy(): void {
    this.subscriptionGroup.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.addToCartClick.next();
    this.addToCartClick.complete();
  }

  /**
  * @description Handles the response from a category list get
  * @param items the response from the communication service
  */
  handleCategoriesGet = (items: EnumCreateParams[]) => this.categories = items;

  /**
  * @description ensures the breadcrumbs reflect the category tree that is currently in the url in the format of
  * /parent/childOfParent/childOfChildOf.../etc
  *
  * @param params
  */
  handleRouteParamsChange = () => {

    if (window.location.href === this.lastHandledUrl)
      return;

    this.lastHandledUrl = window.location.href;
    const uriParts = window.location.href.split("#");
    const filterBy = {
      category: false,
      text: false,
    };

    // Filter the categories
    const keyString = '/products';
    if (uriParts.length > 1 && uriParts[1] !== keyString) {
      const categoryFilter = uriParts[1].replace(/\?.*$/, '').replace(/^\/products\//, '');

      if (categoryFilter.length && categoryFilter !== keyString) {
        let updatedCategory = categoryFilter.replace(/&/g, '%26');
        this.query.filter.uri = { $like: updatedCategory + '%' };

        filterBy.category = true;
      }
    }

    if (uriParts.length > 2) {
      const searchStringMatch = uriParts[2].match(/search=([^&]+)/);

      if (searchStringMatch && searchStringMatch.length > 1) {
        const searchTerm = decodeURI(searchStringMatch[1]);

        this.query.filter['$or'] = {
          name: { $like: '%' + searchTerm + '%' },
          code: { $like: '%' + searchTerm + '%' },
          uri: { $like: '%' + searchTerm + '%' },
          description: { $like: '%' + searchTerm + '%' },
        }

        filterBy.text = true;
      }
    }

    if (!filterBy.category) delete this.query.filter.uri;
    if (!filterBy.text) delete this.query.filter['$or'];

    this.getProducts();
  };

  /**
  * @description Retrives products from the server and attaches them to the component
  */
  getProducts = () => {
    this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
    let params: any = {};

    if (this.queryParams.collection) {
      // this.queryParams.page = 1;
      params.collectionId = this.queryParams.collection;
    }

    if (this.queryParams.list) {
      params.favoritesListId = this.queryParams.list;
    }

    this.fetchProduct(params);
  }

  setSort() {
    this.productService.setSort(undefined);
  }

  updateQueryParams() {
    this.router.navigate([], {
      queryParams: {
        page: this.currentPageActive,
        pageSize: this.query.limit
      },
      queryParamsHandling: 'merge'
    });
  }

  checkAuthenticated() {
    return this.authService.isAuthenticated();
  }

  fetchProduct(params) {
    if (this.checkAuthenticated()) {
      this.productService.getProductList(this.query, params)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(queryResult => {
          this.queryResult = queryResult;
          this.totalCountActive = queryResult.count;
          this.isLoad = true;
        }, error => {
          this.notifications.error('Fetch Product', error.error && error.error.message);
        });
    } else {
      this.productService.getUnAuthProductList(this.query, params)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(queryResult => {
          this.queryResult = queryResult;
          this.totalCountActive = queryResult.count;
          this.isLoad = true;
        }, error => {
          this.notifications.error('Fetch Product', error.error && error.error.message);
        });
    }
  }

  pageChangedActive(page: number) {
    this.currentPageActive = page;
    this.getProducts()
  }

  /**
  * @description Ensures the page number is in sync across multiple pagination components
  *
  * @param {number} pageSize Broadcast pageSize value
  */
  pageSizeChanged(pageSize: number): void {
    this.query.limit = pageSize;
    this.currentPageActive = 1;
    this.getProducts();
  }

  public addCartModel(content, product: NewProduct) {
    this.product = product;
    const withProduct = (product: NewProduct) => {
      this.product = product;
      if (this.product.variations && this.product.variations.length) {
        this.product.variations = this.product.variations.sort((a: any, b: any) =>
          a.displayOrder === b.displayOrder && a.id && b.id ? a.id - b.id : a.displayOrder - b.displayOrder
        );
      }

      this.cartItem = new NewCartItem(this.product);

      this.cartItem.selectedVariation = this.product.variations[0];

      this.addRequiredDecorations();

      this.setSelectableDecorations();

      this.monitorCartAccess();
      this.modalService.dismissAll();
      this.modalService.open(content, { size: 'lg', centered: true, windowClass: "bulk-order" });

      this.getAllocationCollections();
    };

    this.productService.getProductByUri(encodeURIComponent(this.product.uri))
      .subscribe(withProduct, error => {
        this.notifications.warn('Not Found', 'this product is not found');
        console.log(error, "error")
        this.modalService.dismissAll();
      });

    this.monitorCartAccess();
  }


  private addRequiredDecorations() {
    if (!this.product) throw new Error("Cannot add required decorations without a product");
    if (!this.product.decorations || !this.product.decorations.length) return;
    this.product.decorations.forEach(decoration => {
      if (decoration.isRequired) this.addDecorationToCart(decoration.id, false, true);
    });
  }

  addDecorationToCart(decorationId: number | string | undefined, updateSelectable: boolean = true, isRequired = true) {
    if (decorationId) {
      const decoration = this.product.decorations.find(decc => decc.id == +decorationId);

      const decorationClone: NewDecoration = JSON.parse(JSON.stringify(decoration));

      decorationClone.isRequired = isRequired;

      this.cartItem.selectedDecorations?.push(decorationClone);

      if (updateSelectable) {
        this.setSelectableDecorations();
      }
    }
  }

  /**
* @description Removes the indiciated decoration from the cartItem
*/
  public removeDecorationAtIndex(index: number) {
    this.cartItem.selectedDecorations?.splice(index, 1);

    this.setSelectableDecorations();
  }


  public setSelectableDecorations(): void {
    const signature = "ProductDetailComponent.getSelec ableDecorations: ";
    if (!this.product.decorations || !this.product.decorations.length) {
      this.selectableDecorations = [];
      return;
    }

    if (!this.cartItem || !this.cartItem.selectedDecorations || !this.cartItem.selectedDecorations.length) {
      this.selectableDecorations = this.product.decorations;
      return;
    }

    this.selectableDecorations = this.product.decorations.filter(decoration => {
      if (isNullOrUndefined(decoration.maxPerProduct)) {
        return true;
      }

      // Ensure the product doesn't have the maxed out decorations
      const instances = this.cartItem.selectedDecorations?.filter(
        selected => selected.id === decoration.id
      ).length;

      if (instances === 0) {
        return true
      }

      const result = instances && instances < decoration.maxPerProduct;
      return result;
    });
  }

  //product.packSizes && product.packSizes.length
  usePackSizes = (): boolean => !!(this.product.packSizes && this.product.packSizes.length);

  private monitorCartAccess() {
    this.subscriptionGroup.addAll(
      this.securityService.hasCartAccess()
        .subscribe(hasCartAccess => this.hasCartAccess = hasCartAccess),

      this.securityService.isAutomaticCollectionSelection()
        .subscribe(hasAutomaticCollection => this.hasAutomaticCollection = hasAutomaticCollection)
    );
  }

  finalPrice(product: NewProduct): string | null {
    if (!product || !product.price) {
      return null;
    }

    const finalPrice = exactMath.sub(
      product.price,
      product.subsidyAmount || 0
    );

    if (finalPrice > 0) {
      return this.utils.twoDecimalPlacesString(finalPrice);
    }

    return null;
  }

  canAddToCart = (): boolean => !!this.cartItem.selectedVariation && (
    !this.usePackSizes() || !!this.cartItem.selectedPack
  );

  addToCart() {
    if (this.cartItem.selectedDecorations && this.cartItem.selectedDecorations.length) {
      let hasDecorationOptionError = false;
      for (let i = 0; i < this.cartItem.selectedDecorations.length; i++) {
        const decoration = this.cartItem.selectedDecorations[i];
        if (!decoration.options || !decoration.options.length) { continue; }

        const requiredMissingData = decoration.options.filter(option => option.required && (!option.value || !option.value.length));
        if (requiredMissingData && requiredMissingData.length) {
          hasDecorationOptionError = true;
          break;
        }
      }

      if (hasDecorationOptionError) {
        this.notifications.warn('Processing', '"Ensure required options for all selected decorations have been correctly filled.');
        return;
      }
    }

    if (this.product && this.product.id && this.customerId) {
      this.addToCartClick.next();
    }
  }

  performAddToCart() {
    this.isLoading = true;
    const addItemSubscription = this.cartService.addItemToCart(this.cartItem);
    if (addItemSubscription) {
      addItemSubscription
        .pipe(
          debounceTime(200),
          takeUntil(this.unsubscribe$)
        )
        .subscribe(() => {
          this.notifications.success('Adding Item Complete', 'Adding item to cart was successful.');
          this.collectionsService.loadCollections();
          this.cartItem.allocationCollectionConsumption = undefined;
          this.cartItem.allocationCollectionId = undefined;
          this.isLoading = false;
          this.modalService.dismissAll();
        });
    }
  }

  selectPackSize(name: string) {
    const pack = this.product.packSizes.find(pack => pack.name == name);
    if (pack) {
      this.cartItem.selectedPack = pack;
    }
  }

  public openCollectionModal() {
    this.modalService.open(this.collectionModal, { size: 'lg', centered: true, windowClass: "bulk-order" }).result
      .then(
        (allocationCollectionId?: number) => {
          this.cartItem.allocationCollectionId = allocationCollectionId;
          this.performAddToCart();
        },
        () => { },)
  }

  onPageChange(pageNumber: number) {
    this.currentPageActive = pageNumber;
    this.getProducts();
    this.updateQueryParams();
    // localStorage.setItem('productPageNumber', this.currentPageActive.toString());
    window.scroll(0, 0);
  }

  getPageNumbers(): number[] {
    const totalPages = Math.ceil(this.totalCountActive / this.query.limit);
    return Array(totalPages).fill(0).map((x, i) => i + 1);
  }

  // Method to get the total number of pages
  getTotalPages(): number {
    return Math.ceil(this.totalCountActive / this.query.limit);
  }

  get visiblePages(): number[] {
    const range = 2;
    const start = Math.max(1, this.currentPageActive - range);
    const end = Math.min(this.getTotalPages(), this.currentPageActive + range);

    const pages: number[] = [];
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    return pages;
  }


  checkCurrentPage() {
    return this.currentPageActive > 3;
  }

  checkLastPage() {
    return this.currentPageActive < this.getTotalPages() - 2;
  }

  fetchImage() {
    if (this.product.images) {
      const fetchColorUrl = this.product.images.find((image) => image.name.toLowerCase() == this.cartItem.selectedColour);
      if (fetchColorUrl) {
        return fetchColorUrl.url;
      } else {
        return this.product.imageUrl;
      }
    } else {
      return this.product.imageUrl;
    }
  }

  getAllocationCollections() {
    if (!this.product) {
      this.collectionResult = new QueryResult();
      this.allocationCollections = [];
      return;
    }

    this.collectionsService.getCollectionAllocationsForProduct(this.product.id!, this.customerId)
      .subscribe(result => {
        this.collectionResult = result;

        if (this.collectionResult && this.collectionResult.count && this.collectionResult.rows.some(item => !!item.allocationAvailable)) {
          const result = this.collectionResult;
          this.allocationCollections = [];

          result.rows
            .filter(row => row.products.find(collectionProduct => collectionProduct.productId === this.product.id))
            .forEach(allocationCollection => {
              if (allocationCollection.allocationAvailableDistribution) {
                allocationCollection.allocationAvailableDistribution.forEach(distribution => {
                  const namedCollection = assign(distribution, {
                    name: allocationCollection.name
                  });

                  this.allocationCollections.push(namedCollection);
                })
              }
            });

          this.allocationCollections = this.allocationCollections.filter(collection => !!collection.count);
        }
      });
  }
}
